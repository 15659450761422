////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Modifications
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#          Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  12/07/2020    HJordan                       Initial create
//  12/18/2020    HJordan                       Add initializeContentSearch to fetch content when URL params exist
//  01/27/2021    HJordan                       Refactor to ensure default tag, and appropriate input validation on submit
//  01/29/2020    HJordan                       Support mobile / desktop unique IDs needed for successful site search
//  04/18/2022    BBARRON       83828           Check markup for site attribute to call the right search API
//  01/18/2023    BBARRON       97895           Add ability to select multiple tags
//  01/23/2023    BBARRON       98536           Get multiple tags when search button is clicked as well
//  01/25/2023    BBARRON       98828           Move search validation methods and constants to SearchValidation file
//  02/01/2023    BBARRON       99334           Renamd updatePageSearchContent function to initializeContentSearch and fix typo
//  02/07/2023    BBARRON       99963           Don't trigger content search unless content search form exists
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { setSearchQueryParam, getInitialSearchParams, setTagParam } from '../utils/search-utils';
import { searchContentAndUpdateResults } from '../content-search-results/ContentSearchResults';
import { showAllTags, selectTag, selectAllTag, getAllSelectedTags } from '../tag-filters/TagFilters';
import { updateSearchValidationVisibility, validateSearchQuery, getSearchInput } from './SearchValidation';

/** 
 * Simple function to set the tag query string to "all" if there are no provided tag values.
 * Otherwise set the tag query string to all selected tags, separated by commas.
 * @param {String[]} tags string values for the tags
 */
const initializeDefaultTag = tags => {
  if (typeof tags === undefined || tags === null || tags.length === 0 || !tags.some(tag => tag !== '')) {
    selectAllTag();
    setTagParam('all');
  } else {
    setTagParam(tags.join(','));
  }
};

/**
 * Scripts which must be executed on page load in order to establish submit
 * listeners on the search form used for handling "Content Search" functionality.
 *
 * NOTE: These scripts are assigned to any search form assigned the gs-content-search-form
 */
export const contentSearchScripts = () => {
  const contentSearchForm = document.getElementById('gs-content-search-form');
  const searchInput = getSearchInput(false);
  const searchListenerAdded = contentSearchForm ? contentSearchForm.dataset.searchListener : false;

  /**
   * Simple helper function with the responsibility of setting the query url parameter in
   * the url if the provided value is valid
   *
   * @param {String} queryValue value of a search action to be added to the query params
   */
  const manageSearchQueryParam = queryValue => {
    const searchQueryValid = validateSearchQuery(queryValue);

    if (searchQueryValid) {
      setSearchQueryParam(queryValue);
    } else {
      setSearchQueryParam(null);
    }
  };

  /**
   * Form submit handler function which checks the validity of the form values, and
   * then executes a request to execute a content search (which subsequently updates
   * search results in the view)
   *
   * @param {EventTarget} event Event passed by the listener
   */
  const contentSearchClicked = event => {
    event.preventDefault();
    event.stopPropagation();

    const searchInput = getSearchInput(false);
    manageSearchQueryParam(searchInput.value);

    const { section, query } = getInitialSearchParams();
    const searchQueryValid = validateSearchQuery(query);
    const site = contentSearchForm ? contentSearchForm.dataset.site || 'brand' : 'brand';
    const tags = getAllSelectedTags();

    if (searchQueryValid) {
      initializeDefaultTag(tags);
      updateSearchValidationVisibility(searchQueryValid, searchInput, false);
      searchContentAndUpdateResults(section, query, tags, site);
    } else {
      updateSearchValidationVisibility(searchQueryValid, searchInput, false);
    }
  };

  if (contentSearchForm && !searchListenerAdded) {
    contentSearchForm.dataset.searchListener = true;
    contentSearchForm.addEventListener('submit', contentSearchClicked);
    searchInput.addEventListener('blur', event => manageSearchQueryParam(event.target.value));
  }
};

/**
 * Scripts which must be executed on page load in order to establish submit
 * listeners on the search form used for handling "Site Search" functionality.
 *
 * NOTE: These scripts are assigned to any search form assigned the gs-site-search-form
 */
export const siteSearchScripts = () => {
  const siteSearchForm = document.getElementById('gs-site-search-form');
  const mobileSiteSearchForm = document.getElementById('gs-site-search-form-mobile');
  const searchListenerAdded = siteSearchForm ? siteSearchForm.dataset.searchListener : false;
  const mobileSearchListenerAdded = mobileSiteSearchForm ? mobileSiteSearchForm.dataset.searchListener : false;

  /**
   * Form submit handler function which checks the validity of the form values, and
   * then executes a redirect to the appropriate URL if the search term is valid
   *
   * @param {EventTarget} event Event passed by the listener
   * @param {Object} isMobile whether the search is mobile
   */
  const siteSearchSubmitted = (event, isMobile) => {
    event.preventDefault();
    event.stopPropagation();
    const activeInput = getSearchInput(isMobile);
    const searchQuery = activeInput.value;
    const searchQueryValid = validateSearchQuery(searchQuery);

    updateSearchValidationVisibility(searchQueryValid, activeInput, isMobile);

    if (searchQueryValid) {
      const origin = window.location.origin;
      const searchURL = `/search`;
      const newLocation = `${origin}${searchURL}?q=${searchQuery}`;

      window.location.href = newLocation;
    }
  };

  if (siteSearchForm && !searchListenerAdded) {
    siteSearchForm.dataset.searchListener = true;
    siteSearchForm.addEventListener('submit', event => siteSearchSubmitted(event, false));
  }

  if (mobileSiteSearchForm && !mobileSearchListenerAdded) {
    mobileSiteSearchForm.dataset.searchListener = true;
    mobileSiteSearchForm.addEventListener('submit', event => siteSearchSubmitted(event, true));
  }
};

/**
 * When the page loads, we want to update the state of the UI to match the state indicated in the URL query parms.
 * This includes populating the search text and selected tags.
 */
export const initializeContentSearch = () => {
  const { section, tags, query } = getInitialSearchParams();
  const hasTags = tags && tags.length > 0;
  if (!hasTags && !query) {
    // if nothing in the query string, then no initial setup needed
    return;
  }

  const contentSearchForm = document.getElementById('gs-content-search-form');
  if (contentSearchForm) {
    const site = contentSearchForm ? contentSearchForm.dataset.site || 'brand' : 'brand';
    searchContentAndUpdateResults(section, query, tags, site);
  }

  if (hasTags) {
    // Start with all tags visible if any tag is selected/active based on query string.
    // NOTE: if all tags show even when hasTags is false, check session storage.
    // DateTagFilters.js for the news-room page stores show more clikc as a user preference for later.
    showAllTags();
    tags.forEach(tagName => selectTag(tagName));
  }

  if (query && contentSearchForm) {
      const searchFormInput = contentSearchForm.querySelector('input');
      searchFormInput.value = query;
  }
  
};
