////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Modifications
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#          Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  12/07/2020    HJordan                       Initial create
//  01/16/2021    HJordan                       Added timeout to search to allow animation of skeletons
//  01/27/2021    HJordan                       Refactor method name to getInitialSearchParam for readability
//  01/28/2020    HJordan       51238           Support Show More button if results exceed threshold
//  01/29/2020    HJordan                       Bugfix to support firstChild selector when generating cards
//  04/18/2022    BBARRON       83828           Add site parameter to search for Funds Search. Refactoring getCardTemplate into seprate method. Cleaned up generateResultsFromTemplates.
//  04/29/2022    BBARRON       84662           Treat null or undefined section as "all"
//  08/17/2022    BBARRON       90163           Add podcast as a search result type so results appear
//  12/06/2022    BBARRON       95417           Search alternative endpoint and process NoResultsMessage when attribute is present
//  01/18/2023    BBARRON       97895           Add ability to select and search by multiple tags
//  05/12/2023    BBARRON       105895          Handle video results
//  06/20/2023    GCASEY        107293          Add custom google analytics event for no search results
//  07/12/2023    LBALL         56984           Education page paging should allow fewer items on mobile than for desktop
//  09/20/2023    BBARRON       114362          Check for presence of search results on page before listening for window resize
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { searchContent } from '../services/search';
import { getInitialSearchParams } from '../utils/search-utils';
import { showMoreCardsInGroup } from '../card-group/CardGroup';

const skeletonActiveClass = 'gs-cardGroupSkeleton--active';
const activeFadeInClass = 'gs-animateIn-bottom';
const noSearchResultsFeatureAttribute = 'data-no-results-message-enabled';

/**
 * Function which toggles the visibility of skeleton loader based on the toggle flag
 *
 * @param {boolean} toggle flag which defines if skeleton cards should be hidden or visible
 */
const toggleSkeletonVisibility = toggle => {
  const searchSkeletonSection = document.getElementsByClassName('gs-cardGroupSkeleton')[0];

  if (toggle) {
    searchSkeletonSection.classList.add(skeletonActiveClass);
    searchSkeletonSection.classList.add(activeFadeInClass);
  } else {
    searchSkeletonSection.classList.remove(skeletonActiveClass);
    searchSkeletonSection.classList.remove(activeFadeInClass);
  }
};

/**
 *  Sends the search_no_results event to google analytics with the information concerning the search query and tag
 */
const fireNoResultsAnalyticsEvent = () => {
  const params = new URLSearchParams(window.location.search);
  const tag = params.get("tag");
  const query = params.get("query");

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    tag, 
    query,
    event: "search_no_results"
  });
}

/**
 * Async function which executes the API request for content, and upon response,
 * orchestrates the toggling of skeleton cards, and initiates the creation of
 * markup based on the results
 *
 * @param {string} section section param
 * @param {string} query query param
 * @param {string[]} tags array of tags
 * @param {string} site the site to search (funds|brand)
 */
const contentSearch = async (section, query, tags, site) => {
  const response = await searchContent(section, query, tags, site);
  if (response) {
    if (response.SearchResults && response.SearchResults.length > 0) {
      toggleSkeletonVisibility(false);
      removeNoResultsMessage();
      generateResultsFromTemplates(response.SearchResults, site);
    } else {
      toggleSkeletonVisibility(false);
      generateNoResultsMessage(response.NoResultsMessage);
      fireNoResultsAnalyticsEvent();
    }
  } else {
    console.error('Failed to get API response');
  }
};

/**
 * Function to generate the "Show More" button and eventListener registration to
 * the "ShowMore" script functionality
 *
 * @return {Node} button and wrapper generated via the template
 */
const generateShowMoreButton = () => {
  const showMoreTemplate = document.getElementById('gs-search-showMore-template');
  const clone = document.importNode(showMoreTemplate.content, true);

  clone.querySelector('button').addEventListener('click', event => showMoreCardsInGroup(event, 9));

  return clone;
};

/**
 * Function which generates a single Card node based on a dataset
 *
 * @param {object} data data containing attributes of a card
 * @param {boolean} isHidden flag to determine if card should have hidden class applied
 * @return {Node} generated markup for a card
 */
const generateCardNode = (data, isHidden, linkTarget) => {
  const { Type, Title, ShortDescription, CardImage, Date, Category, Url } = data;
  const card = getCardTemplate(Type);
  if(!card) {
    console.warn(`No card template found for type: ${Type}`);
    return null;
  }
  const subTitleNode = card.querySelector('.gs-card-subtitle');
  const svgIcon = card.querySelector('.gs-card-subtitle svg');

  subTitleNode.innerHTML = `${Category}  •  ${Date}`;
  subTitleNode.insertBefore(svgIcon, subTitleNode.firstChild);
  card.querySelector('a').href = Url;
  if(linkTarget) {
    card.querySelector('a').target = linkTarget;
  }
  card.querySelector('img').src = CardImage;
  card.querySelector('h6').innerHTML = Title;
  card.querySelector('.gs-card-content').innerHTML = ShortDescription;

  if (isHidden) {
    card.firstElementChild.classList.add('gs-card--hidden');
  }

  return card;
};

const getCardTemplate = (cardType) => {
  cardType = cardType.toLowerCase();
  let templateId;
  switch(cardType) {
    case "video":
    case "webinar":
      templateId = 'gs-search-card-webinar-template';
      break;
    case "article":
    case "commentary":
    case "podcast":
      templateId = 'gs-search-card-article-template';
      break;
    case "external link":
      templateId = 'gs-search-card-external-link-template'
      break;
    default:
      templateId = null;
      break;
  }

  if(!templateId) {
    return null;
  }

  const resultCardTemplate = document.getElementById(templateId);

  if(!templateId) {
    return null;
  }
  return document.importNode(resultCardTemplate.content, true);
}


const manageMaxVisibleResultCount = () => {

  const maxVisible = getMaxVisibleResultCountFromViewport();
  const searchResults = document.getElementById('gs-content-search-results');
  if (!searchResults) {
    return;
  }

  const resultCardGroups = searchResults.querySelectorAll(".gs-cardgroup .gs-container-inner .gs-cardgroup-content");

  if (resultCardGroups.length > 0) {

    for (let i = 0; i < resultCardGroups.length; i++) {

      let resultCardsVisible = resultCardGroups[i].querySelectorAll("a.gs-card:not(.gs-card--hidden)"),
        resultCards = resultCardGroups[i].querySelectorAll("a.gs-card"),
        resultVisability = false;

      if (!resultCardsVisible.length) {
        continue;
      }

      if (resultCardsVisible.length < maxVisible) {
        resultVisability = true;
      }

      manageResultCardVisbility(maxVisible, resultCards, resultCardsVisible, resultVisability);
    }
  }
}

/**
 * Function hides or shows search result cards
 *
 */

const manageResultCardVisbility = (maxVisible, resultCards, resultCardsVisible, hide = false) => {

  if (!hide) {
    for (let i = maxVisible; i <= resultCardsVisible.length; i++) {
      if (!resultCards[i].classList.contains('gs-card--hidden')) {
        resultCards[i].classList.add('gs-card--hidden');
      }
    }
  } else {
    for (let i = 0; i < maxVisible; i++) {
      if (resultCards[i].classList.contains('gs-card--hidden')) {
        resultCards[i].classList.remove('gs-card--hidden');
      }
    }
  }
}

/**
 * Function outputs the number of results to display based on the viewport
 *
 */

const getMaxVisibleResultCountFromViewport = () => {

  return window.innerWidth < 1024 ? 3 : 9;

}

/**
 * Function which generates the Card Group which will contain the list of items generated
 * based on the provided data set
 *
 * @param {string} title title for the card group
 * @param {array} data list of data elements needed to generate results
 * @return {Node} generated markup of a card group and it's children
 */
const generateSearchResultsNode = (title, data, linkTarget) => {
  const resultCardGroupTemplate = document.getElementById('gs-search-card-group-template');

  const resultCount = data.length;
  let resultsNode;
  let innerResultsNode;
  const maxVisible = getMaxVisibleResultCountFromViewport();

  if (resultCount >= 0) {
    resultsNode = document.importNode(resultCardGroupTemplate.content, true);
    resultsNode.querySelector('h5').innerHTML = `${title} (${resultCount})`;
    innerResultsNode = resultsNode.querySelector('.gs-cardgroup-content');
    innerResultsNode.classList.add(activeFadeInClass);

    for (let i = 0; i < resultCount; i++) {
      const isHidden = i >= maxVisible;
      let card = generateCardNode(data[i], isHidden, linkTarget);
      if(card) {
        innerResultsNode.appendChild(card);
      }
    }

    if (resultCount > maxVisible) {
      innerResultsNode.parentNode.appendChild(generateShowMoreButton());
    }
  }

  return resultsNode;
};

/**
 * Function targets the results section of a page and removes all nodes
 */
const removeVisibleSearchResultNodes = () => {
  const contentResultsSection = document.getElementById('gs-content-search-results');

  while (contentResultsSection.firstChild) {
    contentResultsSection.removeChild(contentResultsSection.lastChild);
  }
};

/**
 * Function which accepts the JSON results from the Brand or Funds Search API, and uses HTML templates
 * to generate the markup needed for the results list. Each type of content gets its own heading and list of cards
 *
 * @param {object} resultsData JSON payload of results
 */
const generateResultsFromTemplates = (resultsData, site) => {
  const contentResultsSection = document.getElementById('gs-content-search-results');
  const { section } = getInitialSearchParams();

  let contentTypes = [];

  if(site == 'funds') {
    contentTypes = [
      {type: 'commentary', sectionHeading: 'Commentary', section: 'commentary'},
      {type: 'external link', sectionHeading: 'News and Media', section: 'newsandmedia'},
      {type: 'podcast', sectionHeading: 'Podcasts', section: 'faithinvesting'},
    ]
  } else {
    contentTypes = [
      {type: 'article', sectionHeading: 'Articles', section: 'articles'},
      {type: 'video', sectionHeading: 'Videos', section: 'videos'},
      {type: 'webinar', sectionHeading: 'Webinars', section: 'webinars'},
    ]
  }

  contentTypes.forEach(c => {
    // If section is present it must be "all" or match the current content type.
    // If section is not present treat it as "all"
    if(!!section && section.toLowerCase() !== c.section && section.toLowerCase() !== "all") {
      return;
    }

    let filteredResults = resultsData.filter(result => result.Type.toLowerCase() === c.type);
    let linkTarget = c.type === 'external link' ? '_blank' : null;
    let contentNode = generateSearchResultsNode(c.sectionHeading, filteredResults, linkTarget);
    if (contentNode) {
      contentResultsSection.appendChild(contentNode);
    }
  });
};

/**
 * Adds the no search results message to the gs-content-search-results container
 */
const generateNoResultsMessage = (message) => {
  const resultsSection = document.getElementById('gs-content-search-results');

  const outer = document.createElement("div");
  outer.classList.add('gs-container');
  outer.classList.add('gs-container-color--primary');
  outer.classList.add('gs-section');
  outer.classList.add('gs-search--no-results');

  const inner = document.createElement("div");
  inner.classList.add('gs-container-inner');

  const content = document.createElement("div");
  content.classList.add('gs-section-content');

  const block = document.createElement("div");
  block.classList.add('gs-rich-content-block');
  block.innerHTML = message;

  content.appendChild(block);
  inner.appendChild(content);
  outer.appendChild(inner);
  resultsSection.appendChild(outer);
}

const removeNoResultsMessage = () => {
  const contentResultsSection = document.getElementById('gs-content-search-results');
  let noResultsContainer = contentResultsSection.querySelector('.gs-search--no-results');
  if(noResultsContainer) {
    noResultsContainer.remove();
  }
}

/**
 * Entry function which orchestrates the execution of an API search, and the update of the DOM
 * based on results received from the API request
 *
 * The request includes a 1 second delay to allow skeleton loaders to animate without a flicker
 * in the case that the API returns too quickly
 *
 * @param {string} section section param
 * @param {string} query query param
 * @param {string} tags tags param
 * @param {string} site the site to search (funds|brand)
 */
export const searchContentAndUpdateResults = (section, query, tags, site) => {
  const contentResultsSection = document.getElementById('gs-content-search-results');

  if (contentResultsSection) {
    removeVisibleSearchResultNodes();
    toggleSkeletonVisibility(true);

    setTimeout(() => {
      contentSearch(section, query, tags, site);
    }, 1000);
  }
};


const searchResults = document.getElementById('gs-content-search-results');
if (searchResults) {
  window.addEventListener("resize", manageMaxVisibleResultCount);
}