// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Modifications
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   Date          Pgmr          WR/IR#          Description
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   12/16/2024    GCASEY        144752          Initial create
//   01/06/2025    GCASEY        143299          Add input selection handling
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////


export const inputFieldScripts = () => {
  /**
   * Mask Input string with given pattern
   * The 0 character is used in place of numbers
   * @param {string} input 
   * @param {string} mask 
   * @returns 
   */
  const maskCharacters = (input, mask) => {
    let result = '';
    let index = 0;

    for (let i = 0; i < mask.length; i++) {
      if (mask[i] === '0' && index < input.length) {
        result += input[index];
        index++;
      } else if (mask[i] !== '0') {
        result += mask[i];
      }

      if (index >= input.length) {
        break;
      }
    }

    return result;
  }

  /**
   * Override default input behavior and add numeric/length restrictions and mask/placeholder
   * @param {HTMLElement} element 
   * @param {string} placeholder 
   * @param {string} mask 
   */
  const restrictInput = (element, placeholder, mask) => {
    element.placeholder = placeholder;

    element.addEventListener("input", (e) => {
      if (e.inputType == "deleteContentBackward") {
        return;
      }

      const numeric = e.target.value.replace(/\D/g, "");
      const masked = maskCharacters(numeric, mask);
      e.target.value = masked.slice(0, mask.length);
    });
  }

  /**
   *  Handles the color of the initial placeholder value in dropdowns
   * @param {Event} event 
   */
  const selectHandler = (event) => {
    const element = event.target;
    const selectedClass = "--selected";

    if(element.value !== "") {
      element.classList.add(selectedClass);
    } else {
      element.classList.remove(selectedClass);
    }
  }

  /**
   *  Handles the toggle button for the visibility of the ssn input field
   * @param {Event} event 
   */
  const ssnButtonHandler = (event) => {
    const element = event.target;
    const selectedClass = "--selected";
    const hiddenClass = "--hidden";

    if (element.classList.contains(selectedClass)) {
      element.classList.remove(selectedClass);
      element.previousElementSibling.classList.remove(hiddenClass);
    } else {
      element.classList.add(selectedClass);
      element.previousElementSibling.classList.add(hiddenClass);
    }

    event.stopImmediatePropagation();
  }


  const dobElements = Array.from(document.getElementsByClassName("gs-form-input-dob"));
  const phoneElements = Array.from(document.getElementsByClassName("gs-form-input-phone"));
  const ssnElements = Array.from(document.getElementsByClassName("gs-form-input-ssn"));
  const ssnIconButtons = Array.from(document.getElementsByClassName("gs-form-input-icon-ssn"));
  const selectElements = Array.from(document.querySelectorAll("select.gs-form-input, select.gs-form-cta-input"));
  dobElements.forEach((dob) => restrictInput(dob, "mm/dd/yyyy", "00/00/0000"));
  phoneElements.forEach((phone) => restrictInput(phone, "(###) ###-####", "(000) 000-0000"));
  ssnElements.forEach((ssn) => restrictInput(ssn, "###-##-####", "000-00-0000"));
  selectElements.forEach((select) => select.addEventListener("change", (e) => selectHandler(e)));
  ssnIconButtons.forEach((btn) => btn.addEventListener("click", (e) => ssnButtonHandler(e)));
}
